import _ from 'underscore'

class TaskTypeEnum {
  key: string
  dscp: string
  color: string
  bgColor: string

  constructor(key: string, dscp: string, color: string, bgColor: string) {
    this.key = key
    this.dscp = dscp
    this.color = color
    this.bgColor = bgColor
  }
}

const toReturn: Record<string, TaskTypeEnum> = {
  ALL: new TaskTypeEnum('all', 'All', '#00a3ed', '#ddf4ff'),
  VDAC: new TaskTypeEnum('external', 'External Accrual', '#e6bf00', '#fbf6e1'),
  OPEN: new TaskTypeEnum('internal', 'Internal Accrual', '#7f39b2', '#f0e4f9'),
  MAN: new TaskTypeEnum('man', 'Manual', '#51c8c4', '#e2fffe'),
  CALCULATED: new TaskTypeEnum(
    'calculated',
    'Calculated',
    '#51c8c4',
    '#e2fffe'
  ),
  CALCULATION: new TaskTypeEnum(
    'calculation',
    'Calculation',
    '#51c8c4',
    '#e2fffe'
  )
}

const methods = {
  getList: (): TaskTypeEnum[] => {
    let keyList: TaskTypeEnum[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined) {
        keyList.push(o)
      }
    })
    return keyList
  },

  getKeyList: (): string[] => {
    let keyList: string[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined) {
        keyList.push(o.key)
      }
    })
    return keyList
  },
  getDscpFromKey: (key: string): string => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.key === key) {
        return true
      }

      return false
    })
    return found ? found.dscp : ''
  },
  getColorFromKey: (key: string): string => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.key === key) {
        return true
      }

      return false
    })

    return found ? found.color : ''
  },
  getBgColorFromKey: (key: string): string => {
    let found = _.find(Object.values(toReturn), (o) => {
      if (o.key === key) {
        return true
      }

      return false
    })

    return found ? found.bgColor : ''
  },
  getKeyValueList: (): { id: string; value: string }[] => {
    let keyList: { id: string; value: string }[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined) {
        keyList.push({ id: o.key, value: o.dscp })
      }
    })
    return keyList
  },
  getKeyValueListNoManual: (): { id: string; value: string }[] => {
    let keyList: { id: string; value: string }[] = []

    Object.values(toReturn).forEach((o) => {
      if (o.key !== undefined && o.key !== 'man' && o.key !== 'calculation') {
        keyList.push({ id: o.key, value: o.dscp })
      }
    })
    return keyList
  },
  getKeyValueListNoManualNoAll: (): { id: string; value: string }[] => {
    let keyList: { id: string; value: string }[] = []

    Object.values(toReturn).forEach((o) => {
      if (
        o.key !== undefined &&
        o.key !== 'man' &&
        o.key !== 'calculation' &&
        o.key !== 'all'
      ) {
        keyList.push({ id: o.key, value: o.dscp })
      }
    })
    return keyList
  }
}

export default Object.freeze({ ...toReturn, ...methods })
