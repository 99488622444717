import { FC, useEffect, useState } from 'react'
import FilterBar from '../components/review-center/FilterBar'
import ReviewCenterTable from '../components/review-center/ReviewCenterTable'
import { getConfig, getDashboardData } from '../services/ReviewCenterAPI'
import ReviewCenterFieldsEnum from '../utils/enums/ReviewCenterFieldsEnum'
import '../review-center.css'
import { MenuItemType } from 'antd/lib/menu/hooks/useItems'
import CreateNewFilterModal from '../components/review-center/modals/CreateNewFilter'
import UpdateFilterModal from '../components/review-center/modals/UpdateFilter'
import { getFilterFieldOptions, getFilters } from '../services/ReviewCenterAPI'
import { utils, writeFileXLSX } from 'xlsx'
// import { applyFilter } from '../utils/rc/rc_utils'
import { Spin } from 'antd'
import { getGlobalSettings } from '../services/TxnManagerAPI'
import RCFieldsNewTRDEnums from '../utils/enums/RCFieldsNewTRDEnums'
import { useGlobalStore } from '../global/global-store'

import consolidatedReviewCenterFields from '../utils/enums/ReviewCenterFieldsEnum'
import consolidatedRCFieldsNewTRDEnums from '../utils/enums/capeTown/RCFieldsNewTRDEnums'

import { useQueryClient } from '@tanstack/react-query'
import useTxnEnableFeatures from './Settings/pages/TxnEnableFeatures/hooks/useTxnEnableFeatures'
import useTxnEnableFeatureStore from './Settings/pages/TxnEnableFeatures/manager/useTxnEnableFeatureStore'
import useTxnGlobalSettings from '../hooks/useTxnGlobalSettings'

const ReviewCenter: FC = (): JSX.Element => {
  const { callReviewCenterReload, setIsEditRowCalled, setIsCreateQjeCalled } =
    useGlobalStore()
  const [config, setConfig] = useState<any>({})
  const [period, setPeriod] = useState<string>('')
  const [fields, setFields] = useState<any>({})
  const [view, setView] = useState<string>('')
  const [data, setData] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [shouldReload, setShouldReload] = useState<boolean>(false)
  const [activeFilter, setActiveFilter] = useState<string | number>('all')
  const [customFilters, setCustomFilters] = useState<any>([])
  const [previewMode, setPreviewMode] = useState(false)
  const [previewTriggeredFrom, setPreviewTriggeredFrom] = useState<any>('')
  const [showCreateFilterModal, setShowCreateFilterModal] =
    useState<boolean>(false)
  const [showUpdateFilterModal, setShowUpdateFilterModal] =
    useState<boolean>(false)
  const [filterFieldOptions, setFilterFieldOptions] = useState<any>()
  const [isLoadingFilterFieldOptions, setIsLoadingFilterFieldOptions] =
    useState<boolean>(true)
  const [dataPreviewed, setDataPreviewed] = useState<any>()
  const { data: txnGlobalSettings } = useTxnGlobalSettings()
  // const [filteredData, setFilteredData] = useState<any>()

  const queryClient = useQueryClient()

  const updateFields = (data: any) => {
    const actions = localStorage.getItem(`quickActions`)
    let fieldsObj =
      actions === 'true'
        ? Object.assign(
            {},
            consolidatedTxnView
              ? consolidatedRCFieldsNewTRDEnums
              : RCFieldsNewTRDEnums
          )
        : Object.assign(
            {},
            consolidatedTxnView
              ? consolidatedReviewCenterFields
              : ReviewCenterFieldsEnum
          )

    fieldsObj['HISTORICALS'][0]['key'] = data.three_month_ago
    fieldsObj['HISTORICALS'][0]['label'] = data.last_month_3_alt
    fieldsObj['HISTORICALS'][1]['key'] = data.two_month_ago
    fieldsObj['HISTORICALS'][1]['label'] = data.last_month_2_alt
    fieldsObj['HISTORICALS'][2]['key'] = data.one_month_ago
    fieldsObj['HISTORICALS'][2]['label'] = data.last_month_1_alt
    fieldsObj['MONTH_TO_DATE'][4]['display'] = !config.display_new_review_center
    fieldsObj['ANALYSIS'][0]['display'] = config.display_analysis_other_column
    fieldsObj['ANALYSIS'][1]['display'] = config.display_analysis_total
    fieldsObj['ANALYSIS'][2]['display'] =
      config.display_budget && config.display_new_review_center
    fieldsObj['ANALYSIS'][3]['display'] = config.display_variance

    fieldsObj['ACCRUALS'][2]['display'] = config.display_ca
    fieldsObj['ACCRUALS'][3]['display'] =
      config.display_imported_on_review_center
    fieldsObj['ACCRUALS'][5]['display'] = config.display_new_review_center
    fieldsObj['ACCRUALS'][6]['display'] = config.display_new_review_center
    fieldsObj['ACCRUALS'][7]['display'] = config.display_new_review_center

    for (const property in fieldsObj) {
      fieldsObj[property] = fieldsObj[property].filter(function (value: any) {
        return value.display
      })
    }
    setFields(fieldsObj)
  }

  const configData = async () => {
    const response = await getConfig()
    if (response && response.data) {
      setConfig(response.data.data.config)
      setView(response.data.data.config.default_view_name)
      setPeriod(response.data.data.config.default_period)
    }
  }

  const dashboardData = async () => {
    setIsLoading(true)
    const response = await getDashboardData(view, period, `${activeFilter}`)
    if (response && response.data && response.data.data && response.status) {
      updateFields(response.data)
      setData(response.data.data)
    }
    setIsLoading(false)
    setShouldReload(false)
  }

  const onViewChange = (view: string) => {
    setIsLoading(true)
    setView(view)
    setShouldReload(true)
  }

  const onPeriodChange = (period: string) => {
    setIsLoading(true)
    setPeriod(period)
    setShouldReload(true)
  }

  const onExportClick = () => {
    const tbl = document.getElementById('tblRC')
    const workbook = utils.table_to_book(tbl)
    const date = new Date()
    const current_date =
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    writeFileXLSX(
      workbook,
      `Gappify-Review-Center-${current_date}-${view}.xlsx`
    )
  }

  const handleFilterMenuOnClick = (item: MenuItemType) => {
    if (item.key === 'new') {
      setShowCreateFilterModal(true)
    } else {
      setActiveFilter(item.key as string | number)
      setShouldReload(true)
    }
  }

  const onCancelModal = (modal: string) => {
    if (previewMode) {
      setPreviewMode(false)
    }

    if (modal === 'new') setShowCreateFilterModal(false)
    if (modal === 'update') setShowUpdateFilterModal(false)
  }

  const reloadFilterFieldOptions = async () => {
    setIsLoadingFilterFieldOptions(true)
    try {
      let { data } = await getFilterFieldOptions()
      setFilterFieldOptions(data)
      setIsLoadingFilterFieldOptions(false)
    } catch (err) {
      console.log('Error:', err)
      setIsLoadingFilterFieldOptions(false)
    }
  }

  const reloadAllFilters = async () => {
    try {
      let { data } = await getFilters('all')
      setCustomFilters(data)
    } catch (err) {
      console.log('Error:', err)
    }
  }

  const { getTxnEnableFeatures } = useTxnEnableFeatures()
  const {
    setConsolidatedTxnView,
    setConsiderBothDebitAndCreditValues,
    consolidatedTxnView
  } = useTxnEnableFeatureStore()
  const {
    isSuccess,
    isRefetching,
    isLoading: isLoadingTxnEnableFeatures,
    data: dataEnableFeatures
  } = getTxnEnableFeatures

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['txn-enable-features'] })
  }, [queryClient])

  useEffect(() => {
    if (!isLoadingTxnEnableFeatures && !isRefetching && isSuccess) {
      setConsolidatedTxnView(
        dataEnableFeatures?.consolidated_transaction_lines!
      )
      setConsiderBothDebitAndCreditValues(
        dataEnableFeatures?.consider_both_debit_and_credit_values!
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isRefetching, isLoadingTxnEnableFeatures])

  useEffect(() => {
    reloadAllFilters()
  }, [])

  useEffect(() => {
    if (showCreateFilterModal || showUpdateFilterModal) {
      reloadFilterFieldOptions()
    }
  }, [showCreateFilterModal, showUpdateFilterModal])

  useEffect(() => {
    configData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPreviewMode(false)
    let reload = async () => {
      if (period !== '' && view !== '' && activeFilter === 'all') {
        dashboardData()
        console.log('Fetching dashboard data .....')
      } else {
        setIsLoading(true)
        console.log('Fetching dashboard data ..........')
        const response = await getDashboardData(view, period, `${activeFilter}`)
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status
        ) {
          // For table headers
          updateFields(response.data)
          // For data itself
          setData(response.data.data)

          // create filter dictionary
          let filterDictionary: any = {}
          customFilters
            ?.find((filter: any) => `${filter.id}` === `${activeFilter}`)
            ?.filter_fields.map((e: any, i: number) => {
              return {
                key: i,
                field: e.column,
                value: e.value
              }
            })
            ?.forEach((filter: any) => {
              if (Object.keys(filterDictionary).includes(filter.field)) {
                filterDictionary[filter.field] = [
                  ...filterDictionary[filter.field],
                  ...filter.value
                ]
              } else {
                filterDictionary[filter.field] = [...filter.value]
              }
            })

          // setFilteredData(
          //   applyFilter(filterDictionary, response.data.data, view)
          // )
        }
        setIsLoading(false)
      }
    }

    // only run this if config already has data
    if (Object.keys(config).length > 0 && ((!isLoading && !data) || shouldReload)) {
      console.log('RELOADING')
      reload()
    }
    setIsEditRowCalled(false)
    setIsCreateQjeCalled(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    period,
    view,
    activeFilter,
    config,
    callReviewCenterReload,
    txnGlobalSettings,
    consolidatedTxnView
  ])

  const getTrdFlag = async () =>
    await getGlobalSettings().then((res) => {
      const { data } = res
      const txnTab = data['transaction.feature_flags']
      localStorage.setItem(
        'isConsolidated',
        txnTab['consolidated_transaction_lines']
      )
      return txnTab.enable_new_transactions
    })

  useEffect(() => {
    getTrdFlag().then((res: string) =>
      localStorage.setItem(`quickActions`, res)
    )
  }, [])

  return (
    <div
      data-testid='review-center'
      data-cy='review-center'
      className='review-center-container'
      id='review-center-page'
    >
      {!isLoadingTxnEnableFeatures && config && data ? (
        <>
          <FilterBar
            config={config}
            onViewChange={onViewChange}
            onPeriodChange={onPeriodChange}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            customFilters={customFilters}
            setCustomFilters={setCustomFilters}
            handleFilterMenuOnClick={handleFilterMenuOnClick}
            setShowUpdateFilterModal={setShowUpdateFilterModal}
            showEditButton={activeFilter !== 'all'}
            onExportClick={onExportClick}
            previewMode={previewMode}
            previewTriggeredFrom={previewTriggeredFrom}
            setIsShowCreateNewFilterModal={setShowCreateFilterModal}
            setIsShowUpdateFilterModal={setShowUpdateFilterModal}
            setIsLoading={setIsLoading}
          ></FilterBar>
          <Spin spinning={isLoading}>
            <ReviewCenterTable
              data={previewMode ? dataPreviewed : data}
              view={view}
              period={period}
              fields={fields}
              loading={isLoading}
              config={config}
              activeFilter={activeFilter}
            ></ReviewCenterTable>
          </Spin>
          <CreateNewFilterModal
            showModal={showCreateFilterModal}
            onCancel={() => onCancelModal('new')}
            setIsShowModal={setShowCreateFilterModal}
            setIsPreviewMode={setPreviewMode}
            setPreviewTriggeredFrom={setPreviewTriggeredFrom}
            settings={filterFieldOptions}
            loadingSettings={isLoadingFilterFieldOptions}
            reloadAllFilters={reloadAllFilters}
            setActiveFilter={setActiveFilter}
            data={data}
            setDataPreviewed={setDataPreviewed}
            view={view}
            period={period}
            updateFields={updateFields}
            setData={setData}
          />
          {activeFilter !== 'all' && (
            <UpdateFilterModal
              showModal={showUpdateFilterModal}
              onCancel={() => onCancelModal('update')}
              setIsShowModal={setShowUpdateFilterModal}
              setIsPreviewMode={setPreviewMode}
              setPreviewTriggeredFrom={setPreviewTriggeredFrom}
              settings={filterFieldOptions}
              loadingSettings={isLoadingFilterFieldOptions}
              reloadAllFilters={reloadAllFilters}
              setActiveFilter={setActiveFilter}
              filterDetails={customFilters.find(
                (filter: any) => `${filter.id}` === `${activeFilter}`
              )}
              activeFilter={activeFilter}
              data={data}
              setDataPreviewed={setDataPreviewed}
              view={view}
              dashboardData={dashboardData}
              period={period}
              updateFields={updateFields}
              setData={setData}
            />
          )}
        </>
      ) : (
        <Spin style={{ margin: 'auto', width: '100%', padding: '10px' }} />
      )}
    </div>
  )
}

export default ReviewCenter
