// ** AntD Imports
import {
  Select,
  TimePicker,
  TableColumnType,
  TableColumnGroupType,
  Form,
  InputNumber
} from 'antd'

// ** Third Party Imports
import { useQueryClient } from '@tanstack/react-query'
import moment, { Moment } from 'moment'

// ** Type Imports
import DataType from '../types/StandardDataType'

// ** Enums **
import DaysTypeEnumObject from '../enums/DaysTypeEnum'
import ActiveEnumObject from '../enums/ActiveEnum'
import TaskTypeEnum from '../enums/TaskTypeEnum'

// ** Utils imports **
import {
  convertMinutesToDaysAndTime,
  onChangeTime,
  onPressEnterField
} from '../utils/dateUtil'
import { rearrangeArray } from '../utils/util'

// ** Zustand Imports **
import useScheduleStore from '../manager/schedule-store'
import StandardDataType from '../types/StandardDataType'
import GlobalHistoryModal from '../../../../../components/GlobalHistoryModal'
import UpdatesData from './UpdatesData'
import { getStandardScheduleHistory } from '../../../../../services/HistoryAPI'
import useRolesAndPermissions from '../../../../../hooks/useRolesAndPermissions'

// ** Styles Imports **
import styles from '../styles/timepicker.module.css'

// =================================================================
export default function StandardData() {
  // ** Hooks **
  const { updateStandardEditPayload, standardEditPayload } = useScheduleStore()
  const queryClient = useQueryClient()

  const standard: Record<string, []> | undefined =
    queryClient.getQueryData(['schedule-manager-standard']) ?? {}

  //  ** Data **
  const { columns: historyColumns } = UpdatesData()
  const RP = useRolesAndPermissions()

  const rowsFromBackend =
    rearrangeArray(
      standard?.content?.filter(
        (item: DataType) => item.workType !== 'VendorTask'
      )
    )?.map((item: Record<string, any>) => ({
      standardScheduleId: item.standardScheduleId,
      key: item.standardScheduleId,
      runOffset: convertMinutesToDaysAndTime(item.runOffsetFullDays!),
      time: moment(
        `${Math.abs(item.runOffsetRemainderHours!)}:${Math.abs(
          item.runOffsetRemainderMinutes!
        )}`,
        ['HH.mm']
      ).format('LT'),
      daysType: item.daysType,
      active: item.active ? 'Yes' : 'No',
      accrualType: TaskTypeEnum.getDscpFromKey(item.taskType!),
      historyId: item.historyId
    })) || []

  const editableRowsFromBackend =
    rearrangeArray(
      standard?.content?.filter(
        (item: DataType) => item.workType !== 'VendorTask'
      ) as DataType[]
    ).map((item: Record<string, any>) => ({
      key: item.taskScheduleId || item.standardScheduleId,
      runOffset: (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Form.Item style={{ margin: 0, marginRight: 2 }}>
            <InputNumber
              max={31}
              min={-31}
              defaultValue={Number(item.runOffsetFullDays)}
              onChange={(e) => {
                updateStandardEditPayload({
                  standardScheduleId: item.standardScheduleId,
                  customerId: item.customerId,
                  envName: item.envName,
                  ...onPressEnterField(
                    'runOffsetFullDays',
                    {
                      ...item,
                      ...(standardEditPayload.find(
                        (i: StandardDataType) =>
                          item.standardScheduleId === i.standardScheduleId
                      ) || {})
                    },
                    e
                  )
                })
              }}
            />
          </Form.Item>
          <span style={{ paddingLeft: 2 }}>day/s</span>
        </div>
      ),
      time: (
        <TimePicker
          className={styles.timepicker}
          popupClassName={styles.timepickerPopup}
          defaultValue={moment(
            `${Math.abs(item.runOffsetRemainderHours!)}:${
              item.runOffsetRemainderMinutes
            }`,
            ['HH.mm']
          )}
          use12Hours
          format='h:mm A'
          onChange={(value: Moment | null) => {
            updateStandardEditPayload({
              standardScheduleId: item.standardScheduleId,
              customerId: item.customerId,
              envName: item.envName,
              ...onChangeTime(
                {
                  ...item,
                  ...(standardEditPayload.find(
                    (i: StandardDataType) =>
                      item.standardScheduleId === i.standardScheduleId
                  ) || {})
                },
                value
              )
            })
          }}
        />
      ),
      daysType: (
        <Select
          defaultValue={item.daysType}
          onChange={(value: string) => {
            updateStandardEditPayload({
              standardScheduleId: item.standardScheduleId,
              customerId: item.customerId,
              envName: item.envName,
              daysType: value
            })
          }}
          options={DaysTypeEnumObject.getKeyValueList().map(
            ({ id, value }) => ({ value: id, label: value })
          )}
        />
      ),
      active: (
        <Select
          defaultValue={item.active}
          onChange={(value: boolean) => {
            updateStandardEditPayload({
              standardScheduleId: item.standardScheduleId,
              customerId: item.customerId,
              envName: item.envName,
              active: value
            })
          }}
          options={ActiveEnumObject.getKeyValueList().map(({ id, value }) => ({
            value: id,
            label: value
          }))}
        />
      ),
      accrualType: TaskTypeEnum.getDscpFromKey(item.taskType!),
      historyId: item.historyId
    })) || []

  let columns: (TableColumnType<any> | TableColumnGroupType<any>)[] = [
    {
      title: '',
      key: 'action',
      width: '50px',
      render: (text: string, record: DataType) => (
        <GlobalHistoryModal
          reloadData={() => {
            return getStandardScheduleHistory(record.historyId!)
          }}
          columns={historyColumns}
          hasExportButton={false}
          disableHistoryModal={!RP.USER_HAS_HISTORY_SHOW_PERMISSION}
        />
      )
    },
    {
      title: 'Run Offset',
      dataIndex: 'runOffset',
      key: 'runOffset',
      width: '300px'
    },
    {
      title: 'Run Time (PST)',
      dataIndex: 'time',
      key: 'time',
      width: '300px'
    },
    {
      title: 'Days Type',
      dataIndex: 'daysType',
      key: 'daysType',
      width: '300px'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width: '300px'
    },
    {
      title: 'Accrual Type',
      dataIndex: 'accrualType',
      key: 'accrualType',
      width: '300px'
    }
  ]

  // ** Mock Data **
  const createRows = (numRows: number) => {
    const runOffsets = [-2, -3, -4]
    const times = ['8:00 AM']
    const daysTypes = ['Calendar', 'Weekdays']
    const actives = [true, false]
    const accrualTypes = ['External', 'Calculated', 'Internal']

    return Array.from({ length: numRows }, (_, i) => {
      const randomRunOffsets =
        runOffsets[Math.floor(Math.random() * runOffsets.length)]
      const randomTimes = times[Math.floor(Math.random() * times.length)]
      const randomDaysTypes =
        daysTypes[Math.floor(Math.random() * daysTypes.length)]
      const randomActives = actives[Math.floor(Math.random() * actives.length)]
      const randomAccrualTypes =
        accrualTypes[Math.floor(Math.random() * accrualTypes.length)]
      return {
        key: (i + 1).toString(),
        runOffset: randomRunOffsets,
        time: randomTimes,
        daysType: randomDaysTypes,
        active: randomActives,
        accrualType: randomAccrualTypes
      }
    })
  }
  const rows = createRows(3)

  return { columns, rows, rowsFromBackend, editableRowsFromBackend }
}
