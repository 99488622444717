import { FC, MouseEventHandler } from 'react'

interface UploadItemProps {
  file: {
    name: string
    uid: string
    transactionId: number
  }
  onClickDownload: (file: {
    name: string
    uid: string
    transactionId: number
  }) => MouseEventHandler<HTMLDivElement>
  dataTestId?: string
  dataCy?: string
}

const UploadItem: FC<UploadItemProps> = ({
  file,
  onClickDownload,
  dataTestId,
  dataCy
}: UploadItemProps): JSX.Element => {
  return (
    <div
      onClick={onClickDownload(file)}
      className='upload-item'
      data-testid={`${dataTestId}-upload-item`}
      data-cy={`${dataTestId}-upload-item`}
    >
      <div className='ant-upload-list ant-upload-list-picture'>
        <div className='ant-upload-list-picture-container'>
          <div className='ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-picture'>
            <div className='ant-upload-list-item-info'>
              <span className='ant-upload-span'>
                <div className='ant-upload-list-item-thumbnail ant-upload-list-item-file'>
                  <span
                    role='img'
                    aria-label='picture'
                    className='anticon anticon-picture'
                  >
                    <svg
                      viewBox='64 64 896 896'
                      focusable='false'
                      data-icon='picture'
                      width='1em'
                      height='1em'
                      fill='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        d='M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 632H136v-39.9l138.5-164.3 150.1 178L658.1 489 888 761.6V792zm0-129.8L664.2 396.8c-3.2-3.8-9-3.8-12.2 0L424.6 666.4l-144-170.7c-3.2-3.8-9-3.8-12.2 0L136 652.7V232h752v430.2z'
                        fill='#1890ff'
                      ></path>
                      <path
                        d='M424.6 765.8l-150.1-178L136 752.1V792h752v-30.4L658.1 489z'
                        fill='#e6f7ff'
                      ></path>
                      <path
                        d='M136 652.7l132.4-157c3.2-3.8 9-3.8 12.2 0l144 170.7L652 396.8c3.2-3.8 9-3.8 12.2 0L888 662.2V232H136v420.7zM304 280a88 88 0 110 176 88 88 0 010-176z'
                        fill='#e6f7ff'
                      ></path>
                      <path
                        d='M276 368a28 28 0 1056 0 28 28 0 10-56 0z'
                        fill='#e6f7ff'
                      ></path>
                      <path
                        d='M304 456a88 88 0 100-176 88 88 0 000 176zm0-116c15.5 0 28 12.5 28 28s-12.5 28-28 28-28-12.5-28-28 12.5-28 28-28z'
                        fill='#1890ff'
                      ></path>
                    </svg>
                  </span>
                </div>
                <span className='ant-upload-list-item-name' title={file.name}>
                  {file.name}
                </span>
                <span className='ant-upload-list-item-card-actions picture'></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadItem
