import { FC, MouseEventHandler } from 'react'
import UploadItem from './UploadItem'

interface UploadListProps {
  data: { name: string; uid: string; transactionId: number }[]
  onClickDownload: (file: {
    name: string
    uid: string
  }) => MouseEventHandler<HTMLDivElement>
}

const UploadList: FC<UploadListProps> = ({
  data,
  onClickDownload
}: UploadListProps): JSX.Element => {
  return (
    <div data-testid='upload-list' data-cy='upload-list'>
      {data.length ? (
        <>
          {data.map((item) => (
            <UploadItem
              dataTestId={item.uid}
              dataCy={item.uid}
              file={item}
              key={item.uid}
              onClickDownload={onClickDownload}
            />
          ))}
        </>
      ) : (
        <>No data</>
      )}
    </div>
  )
}

export default UploadList
