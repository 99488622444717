import {
  DeleteOutlined,
  EditOutlined,
  InboxOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Col, Form, Input, message, Popconfirm, Row, Tabs, Upload } from 'antd'
import download from 'downloadjs'
import moment from 'moment-timezone'
import { FC, useEffect, useState } from 'react'
import GButton from '../components/gappify/GButton'
import { callPostApi, getApiNoPayload } from '../services/GenericAPI'
import {
  getTrdConsolidatedAttachments,
  getTrdConsolidatedComments,
  getTrdConsolidatedHistories
} from '../services/TrdManagerAPI'
import {
  deleteAttachment,
  deleteComment,
  downloadAttachment,
  getAttachments,
  updateComment,
  uploadAttachment
} from '../services/TxnManagerAPI'
import TxnLinesActionTabsPanelProps from '../types/TxnLinesActionTabsPanelProps'
import {
  getDateFormat,
  getMomentTimeFormat,
  getMomentTimezoneFormat
} from '../utils/Date'
import getTracker from '../utils/txn/getTracker'
import GModal from './gappify/GModal'
import LoadMoreButton from './txn-manager/LoadMoreButton'
import UploadList from './txn-manager/UploadList'
import ViewDetailsModal from './ViewDetailsModal'

import type { AxiosError } from 'axios'
import type TxnComment from '../types/TxnComment'
import type TxnHistory from '../types/TxnHistory'
import useRolesAndPermissions from '../hooks/useRolesAndPermissions'

const { TextArea } = Input

const TxnLinesActionTabsPanel: FC<TxnLinesActionTabsPanelProps> = ({
  transactionId,
  historyApi,
  getCommentApi,
  commentActionsApi,
  trackers,
  setAttachmentsList,
  dateTimeFormat,
  isTrd,
  isConsolidatedTrdLines,
  isConsolidated,
  period,
  isFromHeader,
  tsdPeriod
}): JSX.Element => {
  const [history, setHistory] = useState<TxnHistory[]>([])
  const [comments, setComments] = useState<TxnComment[]>([])
  const [attachments, setAttachments] = useState<
    { name: string; uid: string; transactionId: number }[]
  >([])
  const [defaultComment, setDefaultComment] = useState('')
  const [showViewDetails, setShowViewDetails] = useState(false)
  const [historyDetails, setHistoryDetails] = useState({})
  const [addform] = Form.useForm()
  const [timeDateFormatString, setTimeDateFormatString] =
    useState<string>('MM/DD/YYYY hh:mm a')
  const [timezone, setTimeZone] = useState<string>()
  const [totalCommentsPages, setTotalCommentsPages] = useState<number>(1)
  const [totalAttachmentsPages, setTotalAttachmentsPages] = useState<number>(1)
  const [totalHistoryPages, setTotalHistoryPages] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageNum, setPageNum] = useState<number>(1)

  const RP = useRolesAndPermissions()

  const getComments = async () => {
    if (isTrd && isConsolidatedTrdLines) {
      setIsLoading(true)

      try {
        const { data, headers } = await getTrdConsolidatedComments({
          page: 1,
          period
        })

        if (data.length === 0) {
          setTotalCommentsPages(1)
          setPageNum(1)
        }

        setComments(data)
        setTotalCommentsPages(parseInt(headers['x-total-pages']))
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      } finally {
        setIsLoading(false)
      }
    } else {
      const response =
        getCommentApi && (await getApiNoPayload(getCommentApi.url!))

      if (response && response.data) {
        setComments(response.data)
      }
    }
  }

  const getMoreComments = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const { data } = await getTrdConsolidatedComments({
        page: pageNum,
        period
      })

      if (data.length === 0) {
        setTotalCommentsPages(1)
        setPageNum(1)
      } else {
        setPageNum((previousValue) => previousValue + 1)
      }

      const commentsArray: TxnComment[] = [...comments, ...data]
      setComments(commentsArray)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    } finally {
      setIsLoading(false)
    }
  }

  const getHistory = async () => {
    if (isTrd && isConsolidatedTrdLines) {
      setPageNum(1)
      setTotalHistoryPages(1)
      setIsLoading(true)
      try {
        const { data, headers } = await getTrdConsolidatedHistories({
          page: 1,
          period
        })

        const histories: TxnHistory[] = data.map((item: TxnHistory) => {
          if (!item.user) {
            item.user = 'System'
            return item
          }
          return item
        })

        setHistory(histories)
        setTotalHistoryPages(parseInt(headers['x-total-pages']))
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      } finally {
        setIsLoading(false)
        setPageNum((previousValue) => previousValue + 1)
      }
    } else {
      const response = historyApi && (await getApiNoPayload(historyApi.url!))

      if (response && response.data) {
        setHistory(response.data.reverse())
      }
    }
  }

  const getMoreHistories = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const { data } = await getTrdConsolidatedHistories({
        page: pageNum,
        period
      })

      const histories: TxnHistory[] = data.map((item: TxnHistory) => {
        if (!item.user) {
          item.user = 'System'
          return item
        }
        return item
      })

      if (data.length === 0) {
        setTotalHistoryPages(1)
        setPageNum(1)
      } else {
        setPageNum((previousValue) => previousValue + 1)
      }

      const historyArray: TxnHistory[] = [...history, ...histories]
      setHistory(historyArray)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    } finally {
      setIsLoading(false)
    }
  }

  const formatTime = (time: string | number) => {
    if (typeof time === 'string') {
      return moment.tz(timezone!).format(timeDateFormatString)
    } else {
      return moment.unix(time).tz(timezone!).format(timeDateFormatString)
    }
  }

  const handleAddComment = (values: any) => {
    let commentConfig = commentActionsApi?.create_comment
    let payload = {
      ...commentConfig?.payload,
      comment: values.add_comment
    }

    callPostApi(commentConfig!.url, payload).then((res) => {
      addform.resetFields()
      if (res && res.data) {
        getComments()
      }
    })
  }

  const handleEditComment = (id: number) => {
    let payload = {
      comment: defaultComment
    }
    updateComment(id, payload).then((res) => {
      if (res && res.data) {
        getComments()
      }
    })
  }

  const handleOnChangeComment = (e: any) => {
    let value = e.target.value
    setDefaultComment(value)
  }

  const handleDeleteComment = (id: number) => {
    deleteComment(id).then((res) => {
      if (res && res.data) {
        getComments()
      }
    })
  }

  const getAttachmentsList = async () => {
    if (isTrd && isConsolidatedTrdLines) {
      setPageNum(1)
      setTotalAttachmentsPages(1)
      setIsLoading(true)
      try {
        const { data } = await getTrdConsolidatedAttachments({
          // page: 1,
          period
        })
        const formattedList: {
          name: string
          uid: string
          transactionId: number
        }[] = []

        data.forEach((file: any) => {
          formattedList.push({
            name: file.name,
            uid: file.id.toString(),
            transactionId: file.transaction_id
          })
        })

        setAttachments(formattedList.reverse())
        setAttachmentsList && setAttachmentsList(data)
      } catch (error) {
        const { response } = error as AxiosError
        console.log(response)
      } finally {
        setIsLoading(false)
        setPageNum((previousValue) => previousValue + 1)
      }
    } else {
      const thisPeriod = isTrd ? period : tsdPeriod!
      const response = await getAttachments(transactionId!, thisPeriod)

      if (response && response.data) {
        const formattedList: {
          name: string
          uid: string
          transactionId: number
        }[] = []

        response.data.forEach((file: any) => {
          formattedList.push({
            name: file.name,
            uid: file.id.toString(),
            transactionId: file.transaction_id
          })
        })

        setAttachments(formattedList.reverse())
        setAttachmentsList && setAttachmentsList(response.data)
      }
    }
  }

  const getMoreAttachments = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const { data } = await getTrdConsolidatedAttachments({
        // page: pageNum,
        period
      })

      if (data.length === 0) {
        setTotalAttachmentsPages(1)
        setPageNum(1)
      } else {
        setPageNum((previousValue) => previousValue + 1)
      }

      const formattedList: {
        name: string
        uid: string
        transactionId: number
      }[] = []

      data.forEach((file: any) => {
        formattedList.push({
          name: file.name,
          uid: file.id.toString(),
          transactionId: file.transaction_id
        })
      })

      setAttachments(formattedList.reverse())
      setAttachmentsList && setAttachmentsList(data)
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    } finally {
      setIsLoading(false)
    }
  }

  const upload = async (info: any) => {
    /**
     * ENG-8147 - Always enable upload for consolidated Header & Lines pages
     * Exclude transactionId payload for consolidated pages
     * Ref: https://gappify.atlassian.net/browse/ENG-8147?focusedCommentId=116063
     */
    const payload = {
      ...(transactionId && { transactionId }),
      attachment: info.file.originFileObj,
      period: period
    }

    setAttachments(info.fileList.reverse())

    try {
      const response = await uploadAttachment(payload)
      await getAttachmentsList()
      message.success(`${response.data.name} file uploaded successfully`)
    } catch (error: any) {
      const { response } = error
      console.log(response)
      let newlist: any[] = attachments
      newlist.push({
        ...info.file,
        status: 'error'
      })
      setAttachments(newlist.reverse())
      const stringToSearch = 'The attachment must be a file'
      const errorMessage: string = response.data.error.attachment[0]
      const isSubstringFound: boolean = errorMessage
        .toLocaleLowerCase()
        .includes(stringToSearch.toLowerCase())

      if (isSubstringFound) {
        message.error(errorMessage)
      } else {
        message.error('There was a problem uploading your attachment')
      }
    }
  }

  const handleOnDownload = (file: any) => (e: any) => {
    const txnId =
      isTrd && isConsolidatedTrdLines ? file.transactionId : transactionId

    downloadAttachment(txnId, file.uid).then(async (res) => {
      const { data, headers } = res
      const contentType = headers['content-type']
      download(data, file.name, contentType)
    })
  }

  const handleDeleteFile = (attachmentId: string) => {
    deleteAttachment(attachmentId).then((res) => {
      if (res && res.data) {
        getAttachmentsList()
      }
    })
  }

  const handleOnclickTabs = (key: string) => {
    switch (key) {
      case '1':
        getComments()
        break
      case '2':
        getHistory()
        break
      case '3':
        getAttachmentsList()
        break
      case '4':
        break
    }
  }

  const handleClickModal = (item: any) => {
    setDefaultComment(item.comment)
  }

  const handleOnClickViewDetails = (item: any) => {
    setShowViewDetails(true)
    setHistoryDetails(item)
  }

  const handleCancel = () => {
    setShowViewDetails(false)
  }

  const setDefaultTab = () => {
    if (!RP.USER_HAS_TXN_COMMENTS_PERMISSION) {
      if (!RP.USER_HAS_TXN_HISTORY_PERMISSION) {
        if (!RP.USER_HAS_TXN_ATTACHMENTS_PERMISSION) {
          return '4'
        } else {
          getAttachmentsList()
          return '3'
        }
      } else {
        getHistory()
        return '2'
      }
    } else {
      return '1'
    }
  }

  useEffect(() => {
    const getUserTimezone = () => {
      if (dateTimeFormat) {
        const dateFormat = getDateFormat(dateTimeFormat.date_format)
        const timeFormat = getMomentTimeFormat(dateTimeFormat.time_format)
        const timezoneFormat = getMomentTimezoneFormat(
          dateTimeFormat.timezone_format
        )
        setTimeDateFormatString(`${dateFormat} ${timeFormat}`)
        setTimeZone(timezoneFormat)
      } else {
        setTimeZone(moment.tz.guess())
      }
    }
    getUserTimezone() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTimeFormat])

  useEffect(() => {
    const getComments = async () => {
      if (isTrd && isConsolidatedTrdLines && period) {
        try {
          const { data, headers } = await getTrdConsolidatedComments({
            page: pageNum,
            period
          })
          setComments(data)
          setTotalCommentsPages(parseInt(headers['x-total-pages']))
        } catch (error) {
          const { response } = error as AxiosError
          console.log(response)
        }
      } else {
        const response =
          getCommentApi && (await getApiNoPayload(getCommentApi.url!))

        if (response && response.data) {
          setComments(response.data)
        }
      }
    }
    getComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCommentApi, isConsolidatedTrdLines, isTrd, period])

  useEffect(() => {
    const getAttachmentsList = async () => {
      if (isTrd && isConsolidatedTrdLines && period) {
        setPageNum(1)
        setTotalAttachmentsPages(1)
        setIsLoading(true)
        try {
          const { data } = await getTrdConsolidatedAttachments({
            // page: 1,
            period
          })
          const formattedList: {
            name: string
            uid: string
            transactionId: number
          }[] = []

          data.forEach((file: any) => {
            formattedList.push({
              name: file.name,
              uid: file.id.toString(),
              transactionId: file.transaction_id
            })
          })

          setAttachments(formattedList.reverse())
          setAttachmentsList && setAttachmentsList(data)
        } catch (error) {
          const { response } = error as AxiosError
          console.log(response)
        } finally {
          setIsLoading(false)
          setPageNum((previousValue) => previousValue + 1)
        }
      } else {
        const thisPeriod = isTrd ? period : tsdPeriod!
        const response = await getAttachments(transactionId!, thisPeriod)

        if (response && response.data) {
          const formattedList: {
            name: string
            uid: string
            transactionId: number
          }[] = []

          response.data.forEach((file: any) => {
            formattedList.push({
              name: file.name,
              uid: file.id.toString(),
              transactionId: file.transaction_id
            })
          })

          setAttachments(formattedList.reverse())
          setAttachmentsList && setAttachmentsList(response.data)
        }
      }
    }
    getAttachmentsList()
  }, [
    isConsolidatedTrdLines,
    isTrd,
    period,
    setAttachmentsList,
    transactionId,
    tsdPeriod
  ])

  useEffect(() => {
    const getHistory = async () => {
      if (isTrd && isConsolidatedTrdLines && period) {
        setPageNum(1)
        setTotalHistoryPages(1)
        setIsLoading(true)
        try {
          const { data, headers } = await getTrdConsolidatedHistories({
            page: 1,
            period
          })

          const histories: TxnHistory[] = data.map((item: TxnHistory) => {
            if (!item.user) {
              item.user = 'System'
              return item
            }
            return item
          })

          setHistory(histories)
          setTotalHistoryPages(parseInt(headers['x-total-pages']))
        } catch (error) {
          const { response } = error as AxiosError
          console.log(response)
        } finally {
          setIsLoading(false)
          setPageNum((previousValue) => previousValue + 1)
        }
      } else {
        const response = historyApi && (await getApiNoPayload(historyApi.url!))

        if (response && response.data) {
          setHistory(response.data.reverse())
        }
      }
    }
    getHistory()
  }, [historyApi, isConsolidatedTrdLines, isTrd, period])

  return (
    <div
      data-testid='txn-lines-action-tabs'
      data-cy='txn-lines-action-tabs'
      className='txn-lines-action-tabs'
    >
      <Tabs defaultActiveKey={setDefaultTab()} onChange={handleOnclickTabs}>
        {/* Comments */}
        <Tabs.TabPane
          tab={
            <span data-cy='comment-tab' data-testid='comment-tab'>
              Comments
            </span>
          }
          key='1'
          disabled={!RP.USER_HAS_TXN_COMMENTS_PERMISSION}
        >
          {RP.USER_HAS_TXN_COMMENTS_PERMISSION && !isConsolidatedTrdLines && (
            <Form
              form={addform}
              name='basic'
              onFinish={handleAddComment}
              data-testid='txn-lines-comments-tab-form'
              data-cy='txn-lines-comments-tab-form'
            >
              <Form.Item name='add_comment'>
                <TextArea
                  rows={4}
                  disabled={!RP.USER_HAS_ADD_COMMENT_PERMISSION}
                  data-testid='txn-lines-comments-tab-text-area-field'
                  data-cy='txn-lines-comments-tab-text-area-field'
                />
              </Form.Item>
              <div className='flex-left'>
                <GButton
                  dataTestId='txn-lines-comments-tab-add-comments-btn'
                  dataCy='txn-lines-comments-tab-add-comments-btn'
                  htmlType='submit'
                  btnText={'Add Comment'}
                  type='primary'
                  className='g-btn-primary'
                  disabled={!RP.USER_HAS_ADD_COMMENT_PERMISSION}
                />
              </div>
            </Form>
          )}
          {comments.length ? (
            <>
              {comments.map((item) => {
                return (
                  <div
                    className='comments-list-cont'
                    data-testid={`txn-lines-comments-tab-list-section-${item.id}`}
                    data-cy={`txn-lines-comments-tab-list-section-${item.id}`}
                  >
                    <div>
                      <UserOutlined
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                      />
                      <span>{item.comment_by}</span>
                      <span>
                        on {''}
                        {timezone &&
                          moment(item.created_at)
                            .tz(timezone)
                            .format(timeDateFormatString)}
                      </span>
                      <span> [Shown to Licensed users]</span>
                    </div>
                    <div>{item.comment}</div>
                    {!isConsolidatedTrdLines && (
                      <div className='comment-actions scale-up-center'>
                        <GModal
                          dataTestId={`txn-lines-comments-tab-edit-comment-${item.id}`}
                          dataCy={`txn-lines-comments-tab-edit-comment-${item.id}`}
                          title='Edit Comment'
                          btnType='text'
                          btnClassName='g-btn-icon'
                          btnIcon={
                            <EditOutlined
                              onPointerEnterCapture={() => {}}
                              onPointerLeaveCapture={() => {}}
                            />
                          }
                          btnDisabled={!RP.USER_HAS_UPDATE_COMMENT_PERMISSION}
                          okText={'Submit'}
                          cancelButtonProps={{ style: { display: 'none' } }}
                          okButtonProps={{ htmlType: 'submit' }}
                          onOk={() => handleEditComment(item.id)}
                          onClickBtn={() => handleClickModal(item)}
                          content={
                            <TextArea
                              rows={4}
                              defaultValue={defaultComment}
                              onChange={handleOnChangeComment}
                            />
                          }
                        />
                        <Popconfirm
                          title='Are you sure you want to delete this comment?'
                          okText='Yes'
                          cancelText='No'
                          onConfirm={() => handleDeleteComment(item.id)}
                          placement='left'
                        >
                          <GButton
                            dataTestId={`txn-lines-comments-tab-delete-comment-${item.id}`}
                            dataCy={`txn-lines-comments-tab-delete-comment-${item.id}`}
                            type='text'
                            className='g-btn-icon'
                            icon={
                              <DeleteOutlined
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                              />
                            }
                            disabled={!RP.USER_HAS_DELETE_COMMENT_PERMISSION}
                          />
                        </Popconfirm>
                      </div>
                    )}
                  </div>
                )
              })}
            </>
          ) : (
            <>No Data</>
          )}
          {totalCommentsPages > 1 && (
            <LoadMoreButton loading={isLoading} onClick={getMoreComments} />
          )}
        </Tabs.TabPane>
        {/* History */}
        <Tabs.TabPane
          tab={
            <span data-cy='history-tab' data-testid='history-tab'>
              History
            </span>
          }
          key='2'
          disabled={!RP.USER_HAS_TXN_HISTORY_PERMISSION}
        >
          {history.length ? (
            <>
              {history.map((item, index) => {
                return (
                  <div
                    className='history-list-cont'
                    data-testid={`history-tab-details-${index}`}
                    data-cy={`history-tab-details-${index}`}
                  >
                    <div className='history-item'>
                      <div>
                        <UserOutlined
                          onPointerEnterCapture={() => {}}
                          onPointerLeaveCapture={() => {}}
                        />
                        {item.user}
                      </div>
                      <div>{item.message}</div>
                    </div>
                    {item.type?.includes('nudge') && (
                      <span
                        data-testid={`history-tab-view-details-btn-${index}`}
                        data-cy={`history-tab-view-details-btn-${index}`}
                        className='view-details-link'
                        onClick={() => handleOnClickViewDetails(item)}
                      >
                        View Details
                      </span>
                    )}

                    <div>{timezone && formatTime(item.time)}</div>
                  </div>
                )
              })}
            </>
          ) : (
            <>No Data</>
          )}
          {totalHistoryPages > 1 && (
            <LoadMoreButton loading={isLoading} onClick={getMoreHistories} />
          )}
        </Tabs.TabPane>
        {/* Attachments */}
        <Tabs.TabPane
          tab={
            <span data-cy='attachments-tab' data-testid='attachments-tab'>
              Attachments
            </span>
          }
          key='3'
          disabled={!RP.USER_HAS_TXN_ATTACHMENTS_PERMISSION}
          className='attachment-tab'
        >
          {/*
          ENG-8147 - Always enable upload for consolidated Header & Lines pages
          Ref: https://gappify.atlassian.net/browse/ENG-8147?focusedCommentId=116063
          */}
          {/* This is the old condition. Uncomment if need to rollback. */}
          {/* {isConsolidatedTrdLines || (isConsolidated && !isFromHeader) ? ( */}
          {(!isConsolidated && !isFromHeader) ? (
            <>
              <UploadList
                data={attachments}
                onClickDownload={handleOnDownload}
              />
              {totalAttachmentsPages > 1 && (
                <LoadMoreButton
                  loading={isLoading}
                  onClick={getMoreAttachments}
                />
              )}
            </>
          ) : (
            <Upload.Dragger
              data-testid='txn-lines-attachments-tab-upload-dragger'
              data-cy='txn-lines-attachments-tab-upload-dragger'
              listType='picture'
              customRequest={() => {}}
              fileList={attachments}
              onChange={upload}
              accept={
                'image/jpg, image/jpeg, image/png, .xlsx, .xls, .pdf, .doc, .docx, .csv, .zip, .rar, .txt'
              }
              itemRender={(exisiting, file) => {
                return (
                  <div
                    className='upload-item'
                    data-testid={`txn-lines-attachments-tab-upload-item-${file.uid}`}
                    data-cy={`txn-lines-attachments-tab-upload-item-${file.uid}`}
                  >
                    <div onClick={handleOnDownload(file)}>{exisiting}</div>
                    <Popconfirm
                      title='Are you sure you want to delete this file?'
                      okText='Yes'
                      cancelText='No'
                      onConfirm={() => handleDeleteFile(file.uid!)}
                      placement='left'
                    >
                      <GButton
                        dataTestId={`txn-lines-attachments-tab-delete-upload-item-${file.uid}`}
                        dataCy={`txn-lines-attachments-tab-delete-upload-item-${file.uid}`}
                        type='text'
                        className='g-btn-icon upload-item-delete'
                        icon={
                          <DeleteOutlined
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                          />
                        }
                      />
                    </Popconfirm>
                  </div>
                )
              }}
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              </p>
              <p className='ant-upload-text'>Drop files to attach or browse</p>
            </Upload.Dragger>
          )}
        </Tabs.TabPane>
        {/* Tracking */}
        {!isTrd && (
          <Tabs.TabPane
            tab={
              <span data-cy='tracking-tab' data-testid='tracking-tab'>
                Tracking
              </span>
            }
            key='4'
          >
            <div
              className='tracker-item'
              data-testid='txn-lines-tracking-tab'
              data-cy='txn-lines-tracking-tab'
            >
              {trackers?.map((item) => {
                return (
                  <Row>
                    <Col lg={18}>{item.email}</Col>
                    <Col lg={5} className='tracker-time'>
                      {moment(item.created_at)
                        .tz(timezone!)
                        .format('MM/DD/YYYY hh:mm a')}
                    </Col>
                    <Col lg={1}>{getTracker(item, timezone!)}</Col>
                  </Row>
                )
              })}
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>
      <ViewDetailsModal
        showModal={showViewDetails}
        item={historyDetails}
        onCancel={handleCancel}
        dateTimeFormat={timeDateFormatString}
        timezone={timezone}
      />
    </div>
  )
}

TxnLinesActionTabsPanel.defaultProps = {
  isTrd: false,
  isConsolidatedTrdLines: false,
  isConsolidated: false
}

export default TxnLinesActionTabsPanel
